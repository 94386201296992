import { differenceInDays, differenceInMinutes } from "date-fns";
import { format } from "date-fns-tz";

export class Review {
  bookId = null;
  comment = null;
  createdAt = false;
  delivery = {};
  star = 5;
  text = null;

  constructor(review) {
    this.bookId = review.bookId;
    this.comment = review.comment;
    this.createdAt = review.createdAt;
    this.delivery = review.delivery;
    this.star = review.star;
    this.text = review.text;
  }

  get corpUser() {
    return this.delivery && this.delivery.corpUser;
  }

  get corpUserLogoImage() {
    return this.corpUser && this.corpUser.logoImage;
  }

  get displayCreatedAt() {
    if (differenceInDays(new Date(), new Date(this.createdAt)) > 0) {
      return format(new Date(this.createdAt), "yyyy-MM-dd", {
        timeZone: "Asia/Seoul"
      })
    } else {
      let hours = 0;
      let minutes = 0;

      let time = differenceInMinutes(new Date(), new Date(this.createdAt));

      hours = parseInt(time / 60);
      minutes = time % 60;

      return `${hours > 0 ? `${hours}시간 ` : ''}${hours === 0 || minutes > 0 ? `${minutes}분 ` : ''}전`
    }
  }

  get deliveryCompletedDate() {
    return this.delivery && this.delivery.deliveryCompletedDate;
  }

  get receiverAddress() {
    return this.delivery && this.delivery.receiverAddress;
  }

  get receiverAddressSigungu() {
    if (this.receiverAddress) {
      const receiverAddressArr = this.receiverAddress.split(" ");

      return `${receiverAddressArr[0]} ${receiverAddressArr[1]}`
    } else {
      return "";
    }
  }

  get releasedAt() {
    return this.delivery && this.delivery.releasedAt;
  }

  get senderName() {
    return this.delivery && this.delivery.senderName;
  }

  get time() {
    let hours = 0;
    let minutes = 0;

    let time = 0;

    if (this.releasedAt && this.deliveryCompletedDate) {
      time = differenceInMinutes(new Date(this.deliveryCompletedDate), new Date(this.releasedAt));
    }

    hours = parseInt(time / 60);
    minutes = time % 60;

    return `${hours > 0 ? `${hours}시간 ` : ''}${hours === 0 || minutes > 0 ? `${minutes}분` : ''}`
  }
}
