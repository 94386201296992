import React, { memo, useCallback, useEffect } from "react";
import styled from "@emotion/styled";

import { useReviewsStore, useNavStore } from "../stores/hooks";

import Banner from "../components/Banner";
import Corps from "../components/Corps";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Pagination from "../components/Pagination";

const BodyContent = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #202020;
  color: #fff;

  ${({ navOpen }) =>
    navOpen && `
      position: fixed;
      width: 100%;
      height: 100vh;
    `}
`;

const BgTriangle = styled.div`
  width: 100%;
  height: 170px;
  background-image: linear-gradient(
    90deg,
    #EA2845 0%,
    #F65151 100%
  );
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    border-top: 30px solid transparent;
    border-right: 100vw solid #000;
    position: absolute;
    left: 0;
    top: 140px;
  }
`;

const BgTriangleBlack = styled.div`
  width: 100%;
  height: 76px;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 170px;
  border-bottom: 1px solid #C4C4C4;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
`;

const SectionTitle = styled.div`
  padding-top: 10px;
  padding-bottom: 23px;
`;
const Title = styled.h2`
  font-size: 32px;
  font-weight: normal;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
`;

const SectionSummary = styled.div`
  padding-top: 5px;
  height: 75px;
`
const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const SummaryLabel = styled.div`
  font-size: 16px;
  line-height: 16px;
`
const SummaryValue = styled.div`
  font-size: 26px;
  line-height: 31px;
  text-align: right;
  color: #FF6C6C;
  font-family: "RixYeoljeongdoRegular", "Spoqa Han Sans", "Spoqa Han Sans JP",
    sans-serif;
`

const SectionReviews = styled.div`
  padding-top: 15px;
`

const TabsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #797979;
  margin-bottom: 8px;
`

const Stars = styled.div`
  display: flex;
  align-items: center;
`
const Star = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(/assets/images/star.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${({ whole }) => whole && ` 
    background-image: url(/assets/images/star-whole.svg);
  `}

  ${({ half }) => half && ` 
    background-image: url(/assets/images/star-half.svg);
  `}
`
const StarText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #727272;
  margin-left: 5px;
  
`
const StarTextHighlight = styled.span`
  color: #fff;
`

const Tabs = styled.div`
  display: flex;
  align-items: center;
`
const Tab = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #727272;
  cursor: pointer;

  ${({ active }) => active && ` 
    color: #fff;
  `}
`
const TabsDivision = styled.div`
  width: 1px;
  height: 14px;
  background-color: #727272;
  margin: 0 5px;
`

const ReviewsWrap = styled.div`
  margin-bottom: 16px;
`

const Review = styled.div`
  padding: 10px 0 14px;
  display: flex;
  position: relative;
  border-bottom: 1px solid #797979;
`
const ReviewCorpImageWrap = styled.div`
  width: 70px;
  margin-left: 4px;
  margin-right: 16px;
`
const ReviewCorpImage = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #ddd;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ image }) => image || "/assets/images/corps/doobalhero.svg"})
`
const ReviewCreatedAt = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: #727272;
  text-align: center;
  width: 100%;
  margin-top: 8px;
`
const ReviewItemBody = styled.div`
  flex: 1;
`
const ReviewTitle = styled.div`
  display: flex;
  margin-bottom: 2px;
`
const ReviewCorpName = styled.div`
  font-size: 14px;
  line-height: 16px;
`
const ReviewStars = styled.div`
  display: flex;
  align-items: center;
`
const ReviewStar = styled.div`
  width: 10px;
  height: 10px;
  background-image: url(/assets/images/star-s.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 2px;

  ${({ whole }) => whole && ` 
    background-image: url(/assets/images/star-whole-s.svg);
  `}

  ${({ half }) => half && ` 
    background-image: url(/assets/images/star-half-s.svg);
  `}
`

const ReviewTime = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #FF6C6C;
  margin-bottom: 5px;
`
const ReviewMessage = styled.div`
  font-size: 12px;
  line-height: 16px;
`
const ReviewComment = styled.div`
  margin-top: 8px;
  font-size: 12px;
  line-height: 16px;
  padding-left: 19px;
  position: relative;
`

const IconComment = styled.div`
  width: 12px;
  height: 14px;
  background-image: url(/assets/images/comment.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: 0;
  top: 3px;
`

const SectionsCorp = styled.div`
  margin-bottom: 72px;
`
const SectionsCorpTitle = styled.div`
  font-size: 18px;
  line-height: 23px;
  padding-bottom: 8px;
  border-bottom: 1px solid #333;
  margin-bottom: 16px;
` 

const SectionsBanners = styled.div``

const ReviewComponent = memo(({ review }) => {
  return (
    <Review>
      <ReviewCorpImageWrap>
        <ReviewCorpImage image={review.corpUserLogoImage} />   
        <ReviewCreatedAt>{review.displayCreatedAt}</ReviewCreatedAt>
      </ReviewCorpImageWrap>
      <ReviewItemBody>
        <ReviewTitle>
          <ReviewCorpName>{review.senderName}</ReviewCorpName>
          <ReviewStars>
            <ReviewStar whole={review.star >= 1} />
            <ReviewStar whole={review.star >= 2} />
            <ReviewStar whole={review.star >= 3} />
            <ReviewStar whole={review.star >= 4} />
            <ReviewStar whole={review.star === 5} />
          </ReviewStars>
        </ReviewTitle>
        <ReviewTime>출차 후 완료까지: {review.time} ({review.receiverAddressSigungu})</ReviewTime>
        <ReviewMessage>{review.text}</ReviewMessage>
        {review.comment && (
          <ReviewComment>
            <IconComment />
            {review.comment}
          </ReviewComment>
        )}
        
      </ReviewItemBody>
    </Review>
  )
});

function Index() {
  const { count, pageCount, query, reviews } = useReviewsStore().state;
  const { ...actions } = useReviewsStore();

  const { navActive } = useNavStore().state;

  useEffect(() => {
    fetchAll();
    fetchCount();
  }, [query.page, query.hasComment]);

  const fetchAll = useCallback(async () => {
    try {
      await actions.fetch();
    } catch(e) {
      console.log(e)
    }
  }, [actions.fetch]);


  const fetchCount = useCallback(async () => {
    try {
      await actions.fetchCount();
    } catch(e) {
      console.log(e)
    }
  }, [actions.fetchCount]);

  const handleGoToPage = useCallback(({ page }) => {
    actions.setQuery({ page });
    window.scrollTo(0, 0);
  }, [actions.setQuery]);

  const handleChangeTab = useCallback(({ hasComment }) => {
    actions.setQuery({ hasComment })
  }, [actions.setQuery]);

  return (
    <BodyContent navOpen={navActive}>
      <BgTriangle />
      <BgTriangleBlack />
      <Nav />
      <SectionTitle>
        <Container>
          <Title>
            당일도착 택배서비스<br />
            생생한 리뷰
          </Title>
        </Container>
      </SectionTitle>
      <SectionSummary>
        <Container>
          <SummaryRow>
            <SummaryLabel>배송 누적건 :</SummaryLabel>
            <SummaryValue>3,000,000건</SummaryValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryLabel>배송 속도 :</SummaryLabel>
            <SummaryValue>02시간 13분</SummaryValue>
          </SummaryRow>
        </Container>
      </SectionSummary>
      <SectionReviews>
        <Container>
          <TabsWrap>
            <Stars>
              <Star whole />
              <Star whole />
              <Star whole />
              <Star whole />
              <Star half />
              <StarText>
                <StarTextHighlight>4.9</StarTextHighlight>/5.0
              </StarText>
            </Stars>
            <Tabs>
              <Tab 
                active={query.hasComment === null}
                onClick={() => handleChangeTab({
                  hasComment: null
                })}
              >
                전체({count.totalCount || 0})
              </Tab>
              <TabsDivision />
              <Tab
                active={query.hasComment === "1"}
                onClick={() => handleChangeTab({
                  hasComment: "1"
                })}
              >
                답변리뷰({count.totalCountWithComment || 0})
              </Tab>
            </Tabs>
          </TabsWrap>
          <ReviewsWrap>
            {reviews.map((row, i) => (
              <ReviewComponent review={row} key={i} />
            ))}
          </ReviewsWrap>
          <Pagination
            page={query.page}
            pageCount={pageCount}
            onChange={handleGoToPage}
          />
        </Container>
      </SectionReviews>
      <SectionsCorp>
        <Container>
          <SectionsCorpTitle>두발히어로를 선택한 기업들</SectionsCorpTitle>
          <Corps />
        </Container>
      </SectionsCorp>
      <SectionsBanners>
        <Container>
          <Banner />
        </Container>
      </SectionsBanners>
      <Footer />
    </BodyContent>
  );
};

export default Index;
