import React, { createContext, useCallback, useContext, useReducer } from "react";
import { Review } from "../../Models";

import api from "../Api";

export const CONSTANTS = {
  FETCH: "FETCH",
  FETCH_COUNT: "FETCH_COUNT",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  count: {
    totalCount: 0,
    totalCountWithComment: 0,
  },
  reviews: [],
  query: {
    page: 1,
    pageSize: 8,
    hasComment: null,
    isPublic: true,
  },
  pageCount: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH:    
      return {
        ...state,
        reviews: action.reviews,
        pageCount: action.pageCount,
      };
    case CONSTANTS.FETCH_COUNT:    
      return {
        ...state,
        count: {
          totalCount: action.totalCount,
          totalCountWithComment: action.totalCountWithComment,
        },
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useReviewsStore = () => {
  const { dispatch, state } = useContext(Context);

  const fetch = useCallback(async () => {
    const { items, totalCount } = await api.get(`/reviews`, state.query);
    
    dispatch({ 
      type: CONSTANTS.FETCH, 
      reviews: items.map((r) => new Review(r)),
      pageCount: Math.floor(totalCount / state.query.pageSize) + (totalCount % state.query.pageSize ? 1 : 0),
    });
  }, [api.get, dispatch, state.query]);

  const fetchCount = useCallback(async () => {
    const { totalCount, totalCountWithComment } = await api.get(`/reviews/count`);
    
    dispatch({ 
      type: CONSTANTS.FETCH_COUNT, 
      totalCount,
      totalCountWithComment,
    });
  }, [api.get, dispatch]);

  const setQuery = useCallback((query = {}) => {
    dispatch({
      type: CONSTANTS.SET_QUERY,
      query,
    });
  }, [dispatch]);


  return {
    state,
    fetch,
    fetchCount,
    setQuery,
  };
};
