const parseObjToBodyString = obj => {
  return Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join("&");
};

const api = {};

api.get = (endpoint, query = {}) => {
  let isJson = true;
  
  const URL = `${
    process.env.REACT_APP_API_HOST
  }${endpoint}?${parseObjToBodyString(query)}`;
  return fetch(URL, {
    credentials: "include",
    method: "GET",
    mode: "cors",
  })
    .then(res => {
      var contentType = res.headers.get("content-type");
      if (contentType.includes("openxmlformats")) {
        isJson = false;
        return res.blob();
      } else {
        return res.json();
      }
    })
    .then(res => {
      if (!isJson) {
        return res;
      }

      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.post = (endpoint, body = {}) => {
  const URL = `${process.env.REACT_APP_API_HOST}${endpoint}`;
  return fetch(URL, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "post",
    mode: "cors"
  })
    .then(res => res.json())
    .then(res => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.put = (endpoint, body = {}) => {
  const URL = `${process.env.REACT_APP_API_HOST}${endpoint}`;
  return fetch(URL, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "put",
    mode: "cors"
  })
    .then(res => res.json())
    .then(res => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

api.delete = endpoint => {
  const URL = `${process.env.REACT_APP_API_HOST}${endpoint}`;
  return fetch(URL, {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "delete",
    mode: "cors"
  })
    .then(res => res.json())
    .then(res => {
      if (res.statusCode >= 300) {
        const error = new Error();
        error.status = res.statusCode;
        error.statusCode = res.statusCode;
        error.message = res.error || res.message;
        throw error;
      }

      return res;
    });
};

export default api;
