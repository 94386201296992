import React, { useCallback } from "react";
import styled from "@emotion/styled";

const PaginationsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 72px;
`
const Page = styled.div`
  height: 40px;
  width: 32px;
  background-color: #000000;
  border: 1px solid #4B4B4B;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  transform: skew(-20deg);
  margin: 0 3px;
  cursor: pointer;

  ${({ active }) => active && `
    background-color: #FF6C6C;
  `}

  ${({ overHundred }) => overHundred && `
    width: 40px;
  `}
`
const PaginationImage = styled.img`
  width: 11px;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  transform: skew(20deg);
`
const PaginationText = styled.div`
  font-size: 20px;
  -webkit-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -o-transform: skew(20deg);
  transform: skew(20deg);
`

function Pagination({ page, pageCount, onChange }) {
  let endPage = (Math.ceil(page / 5)) * 5;
  let startPage = endPage - 4;

  if (pageCount < endPage) {
    endPage = pageCount;
  }

  const handleGoToPage = useCallback((_page) => {
    onChange({ page: _page })
  }, []);

  const handleNextPage = useCallback(() => {
    if (page < pageCount) {
      onChange({ page: page + 1 })
    }
  }, [page, pageCount]);

  const handlePreviousPage = useCallback(() => {
    if (page > 1) {
      onChange({ page: page - 1 })
    }
  }, [page]);

  let pages = [];

  for (let number = startPage; number <= endPage; number++) {
    pages.push(
      <Page
        key={number}
        active={number === page}
        onClick={() => handleGoToPage(number)}
        overHundred={number > 99}
      >
        <PaginationText>{number}</PaginationText>
      </Page>
    );
  }

  return (
    <PaginationsWrap>
      <Page onClick={handlePreviousPage}>
        <PaginationImage 
          alt="이전 페이지 버튼 아이콘"
          src="/assets/images/icon-prev.svg" 
        />
      </Page>
      {pages}
      <Page onClick={handleNextPage}>
        <PaginationImage 
          alt="다음 페이지 버튼 아이콘"
          src="/assets/images/icon-next.svg" 
        />
      </Page>
    </PaginationsWrap>
  );
}

export default Pagination;
