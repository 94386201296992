import React, { memo } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

const CorpsWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  gap: 10px 0;

  @media (max-width: 767px) {
    grid-template-columns: repeat(10, 1fr);
  }

  @media (max-width: 710px) {
    grid-template-columns: repeat(9, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 590px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (max-width: 510px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 445px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 374px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Corp = styled.div``;

const CorpImage = styled.div`
  width: 60px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/images/corps/${({ image }) => image || ""});
`;

const corpList = [
  {
    name: "에디톨로지",
    image: "editology.svg",
    url: "https://smartstore.naver.com/basixm",
  },
  {
    name: "레드마켓",
    image: "redmarket.svg",
    url: "http://mobile.tmon.co.kr/deals/8823694154",
  },
  {
    name: "낫베이직",
    image: "louiescreamery.svg",
    url: "www.louiescreamery.com",
  },
  {
    name: "홀드미커피",
    image: "holdmecoffee.svg",
    url: "https://holdme.coffee/-intro",
  },
  {
    name: "찍스",
    image: "zzixx.svg",
    url: "https://www.zzixx.com/",
  },
  {
    name: "한국생활건강",
    image: "kbh.svg",
    url: "http://www.kbh.kr/default/",
  },
  {
    name: "프레시코드",
    image: "freshcode.svg",
    url: "https://www.freshcode.me/",
  },
  {
    name: "멜드앤코튼블랙",
    image: "cottonblack.svg",
    url: "https://cottonblack.co.kr/",
  },
  {
    name: "LG헬로모바일",
    image: "lghellovision.svg",
    url: "https://direct.lghellovision.net/main.do",
  },
  {
    name: "플라이북",
    image: "flybook.svg",
    url: "https://www.flybook.kr/",
  },
  {
    name: "SK7모바일",
    image: "sk7mobile.svg",
    url: "https://www.sk7mobile.com/",
  },
  {
    name: "아프리모",
    image: "afrimo.svg",
    url: "https://afrimo.net/",
  },
  {
    name: "데싱디바",
    image: "dashingdiva.svg",
    url: "https://dashingdiva.co.kr/",
  },
  {
    name: "파스토",
    image: "fassto.svg",
    url: "https://www.fassto.ai/",
  },
  {
    name: "스테이지파이브",
    image: "stagefive.svg",
    url: "https://www.stagefive.com/",
  },
  {
    name: "한스타일",
    image: "hanstyle.svg",
    url: "https://shop.ihanstyle.com/",
  },
  {
    name: "더블유아일랜드(윙블링)",
    image: "wingbling.svg",
    url: "https://wingbling.co.kr/",
  },
  {
    name: "브랜디",
    image: "brandi.svg",
    url: "https://www.brandi.co.kr/",
  },
  {
    name: "국민은행리브엠",
    image: "liivm.svg",
    url: "https://www.liivm.com/",
  },
  {
    name: "유플러스알뜰모바일",
    image: "uplussave.svg",
    url: "https://home.uplussave.com/cmmn/index.mhp",
  },
  {
    name: "회이팅",
    image: "hwoieating.svg",
    url: "https://hwoieating.com/",
  },
  {
    name: "KTM모바일",
    image: "ktmmobile.svg",
    url: "https://www.ktmmobile.com/main.do",
  },
  {
    name: "홍언니고기",
    image: "sisterhong.svg",
    url: "https://sisterhong.co.kr/",
  },
  {
    name: "딜리버드",
    image: "dealibird.svg",
    url: "https://dealibird.com/",
  },
  {
    name: "패스트박스",
    image: "fastbox.svg",
    url: "https://fastbox.co.kr/kr/index.php",
  },
  {
    name: "준쥬얼리",
    image: "junjewelry.svg",
    url: "https://www.junjewelry.com/",
  },
  {
    name: "골드팡",
    image: "goldpang.svg",
    url: "https://www.goldpang.com/",
  },
  {
    name: "코코수니",
    image: "cocosoonee.svg",
    url: "http://cocosoonee.com/",
  },
  {
    name: "메종조",
    image: "maisonjo.svg",
    url: "https://maisonjo.co.kr/",
  },
  {
    name: "오벨리",
    image: "obelee.svg",
    url: "https://obelee.com/",
  },
  {
    name: "쿠마이츠",
    image: "kumaeats.svg",
    url: "https://kumaeats.fish/",
  },
  {
    name: "커스텀네일",
    image: "keepu.svg",
    url: "https://keepu.co.kr/product/list.html?cate_no=42",
  },
  {
    name: "베블링",
    image: "babblring.svg",
    url: "https://babblring.com/",
  },
  {
    name: "스윗밸런스",
    image: "sweetbalance.svg",
    url: "https://sweetbalance.kr/",
  },
  {
    name: "더포터하우스",
    image: "theporterhouse.svg",
    url: "https://theporter.house/-guide",
  },
  {
    name: "오렌지스펙트럼",
    image: "accstore.svg",
    url: "http://accstore.co.kr/",
  },
  {
    name: "폰에이씨씨",
    image: "phoneppu.svg",
    url: "https://phoneppu.com/",
  },
  {
    name: "떡함지",
    image: "dduckhamji.svg",
    url: "https://dduckhamji.com/",
  },
  {
    name: "닥터대디미온",
    image: "drdaddy.svg",
    url: "http://www.drdaddy.co.kr/",
  },
  {
    name: "최우영수산",
    image: "chwy.svg",
    url: "https://smartstore.naver.com/chwy",
  },
  {
    name: "새들러하우스",
    image: "saddler.svg",
    url: "https://saddler.haus/-product/list",
  },
  {
    name: "YSK SEOUL",
    image: "yskseoul.svg",
    url: "https://yskseoul.com",
  },
  {
    name: "푸드대시",
    image: "fooddash.svg",
    url: "https://fooddash.co.kr",
  },
  {
    name: "한스 팩토리",
    image: "hansfactory.svg",
    url: "https://smartstore.naver.com/hansfactory2",
  },
  {
    name: "젠제로",
    image: "zenzero.svg",
    url: "https://zenzero.co.kr/-v2/feed",
  },
  {
    name: "닥터나우",
    image: "drnow.svg",
    url: "https://drnow.co.kr",
  },
  {
    name: "우먼스9999",
    image: "godmaskshop.svg",
    url: "https://smartstore.naver.com/godmaskshop/",
  },
  {
    name: "vyvy",
    image: "vyvy.svg",
    url: "http://vyvy.co.kr/",
  },
  {
    name: "crassiang",
    image: "crassiang.svg",
    url: "https://crassiang.com/",
  },
  {
    name: "oliveyoung",
    image: "oliveyoung.svg",
    url: "https://www.oliveyoung.co.kr/",
  },
  {
    name: "라누보",
    image: "lanouvo.svg",
    url: "https://lanouvo.com/",
  },
  {
    name: "밋포밋",
    image: "meetformeat.svg",
    url: "http://meetformeat.co.kr/",
  },
  {
    name: "덱스터고든",
    image: "dextergordonshop.svg",
    url: "https://www.dextergordonshop.com/",
  },
  {
    name: "이미트24",
    image: "baemin.svg",
    url: "https://www.baemin.com/",
  },
  {
    name: "복순도가",
    image: "boksoon.svg",
    url: "https://smartstore.naver.com/boksoon_go/category/ALL?cp=1",
  },
  {
    name: "아몬즈",
    image: "amondz.svg",
    url: "https://m.amondz.com/",
  },
  {
    name: "라벨오브스완",
    image: "labelofswan.svg",
    url: "https://smartstore.naver.com/labelofswan",
  },
  {
    name: "수초명가 비바소녀",
    image: "vivagirl.svg",
    url: "https://vivagirl.co.kr/",
  },
  {
    name: "더분",
    image: "theboon.svg",
    url: "https://www.theboon.co.kr/",
  },
  {
    name: "체킷",
    image: "chekit.svg",
    url: "https://litt.ly/chekit.official",
  },
  {
    name: "육민심서",
    image: "6min.svg",
    url: "https://6min.co.kr/product/list.html?cate_no=23",
  },
  {
    name: "더블D",
    image: "doubled.svg",
    url: "https://front.wemakeprice.com/partnermall/dd",
  },
  {
    name: "오늘와규",
    image: "flavr.svg",
    url: "https://smartstore.naver.com/flavr/products/7377314529",
  },
  {
    name: "블랙업",
    image: "blackup.svg",
    url: "https://black-up.kr/",
  },
  {
    name: "코코닼",
    image: "kokodak.svg",
    url: "https://kokodak.kr/",
  },
];

const shuffleArr = (arr) => {
  let currentIndex = arr.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = arr[currentIndex];
    arr[currentIndex] = arr[randomIndex];
    arr[randomIndex] = temporaryValue;
  }
  return arr;
};

const CorpComponent = ({ image, url }) => {
  return (
    <Link to={{ pathname: url }} target="_blank">
      <Corp>
        <CorpImage image={image} />
      </Corp>
    </Link>
  );
};

const Corps = memo(() => {
  return (
    <CorpsWrap>
      {shuffleArr(corpList).map((row, idx) => (
        <CorpComponent key={idx} image={row.image} url={row.url} />
      ))}
    </CorpsWrap>
  );
});

export default Corps;
