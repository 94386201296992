import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import Main from "./pages/index";

import {
  NavStore,
  ReviewsStore,
} from './stores';

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children,
  );

function App() {
  return (
    <AppProvider
      contexts={[
        NavStore.Provider,
        ReviewsStore.Provider,
      ]}
    >
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route path="*">
            <Redirect to="/"></Redirect>
          </Route>
        </Switch>
      </BrowserRouter>
    </AppProvider>
);
}

export default App;

